export const ACTIONS = {
  SET_SEARCH_RESULTS: 'SEARCHES/SET_SEARCH_RESULTS',

  SEARCH_BY_TITLE: 'SEARCHES/SEARCH_BY_TITLE',
  SEARCH_BY_TITLE_SUCCESS: 'SEARCHES/SEARCH_BY_TITLE_SUCCESS',
  SEARCH_BY_TITLE_FAILURE: 'SEARCHES/SEARCH_BY_TITLE_FAILURE',

  SEARCH_BY_UUID: 'SEARCHES/SEARCH_BY_UUID',
  SEARCH_BY_UUID_SUCCESS: 'SEARCHES/SEARCH_BY_UUID_SUCCESS',
  SEARCH_BY_UUID_FAILURE: 'SEARCHES/SEARCH_BY_UUID_FAILURE',

  SET_CACHED_FILTERS: 'SEARCHES/SET_CACHED_FILTERS',
};
